<template>
  <layout full>
    <div class="operation">
      <span class="cancel" @click="cancel">{{ $t('lang.cancel') }}</span>
      <span>{{ $t('lang.newPost') }}</span>
      <span class="send" @click="send">{{ $t('lang.share') }}</span>
    </div>
    <div>
      <van-field v-model="content" :placeholder="$t('lang.shareTips')" type="textarea" autosize />
      <van-uploader v-model="fileList" multiple accept="image/*" :after-read="afterRead" @delete="deletePic" style="margin: 18px 0 0 18px;" />
    </div>
    <van-overlay :show="isLoading">
      <van-loading class="loading" type="spinner" color="#E5E5E5" vertical>{{ $t('lang.onTheChain') }}</van-loading>
    </van-overlay>
  </layout>
</template>

<script>
import { Field, Uploader, Loading, Overlay } from 'vant'
import common from '@/utils/common'
import {fileToMetaFile, setAttachments} from "sdk";
export default {
  name: "Photo",
  data() {
    return {
      content: '',
      fileList: [],
      filesTemp: [],
      isLoading: false
    }
  },
  components: {
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Loading.name]: Loading,
    [Overlay.name]: Overlay
  },
  methods: {
    cancel() {
      this.$router.back()
    },
    async afterRead(file) {
      let files = file instanceof Array ? file : [file]
      for (let i =0; i< files.length; i++) {
        const file = await fileToMetaFile(files[i].file)
        this.filesTemp.push(file)
      }
    },
    addSimplePhotoShareProtocols(needConfirm, checkOnly, amount) {
      return new Promise(async (resolve, reject) => {
        let params = {
          "createTime": Math.round(new Date()),
          "description": this.content,
          "tags": [],
          'mention': [],
          "photos": JSON.parse(JSON.stringify(this.filesTemp)),
        }

        const { data, attachments} = await setAttachments(params,[
          { name: 'photos', encrypt: '0'}
        ])

        const txData = common.getTxData({
          nodeName: 'SimplePhotoShare',
          brfcId: '88a4a0bdebcb',
          path: '/Protocols/SimplePhotoShare',
          data: JSON.stringify(data),
          attachments: attachments,
          needConfirm,
          checkOnly,
          payTo: amount ? [
            {
              amount,
              address: process.env.VUE_APP_PlatformAddress
            },
            {
              amount: 2000,
              address: process.env.VUE_APP_PlatformAddress
            }
          ] : []
        })

        this.$store.state.sdk.sendMetaDataTx(txData).then(res=>{
          resolve(res)
        }, (err) => {
          reject(err)
        })
      })
    },
    async send() {
      if (!this.content && !this.filesTemp.length) {
        this.$toast(this.$t('lang.emptyContent'))
        return
      }
      this.isLoading = true
      try {
        let photoAmountRes = await this.addSimplePhotoShareProtocols(false, true)
        this.isLoading = false
        if ((photoAmountRes && photoAmountRes.code === 200) || (photoAmountRes && photoAmountRes.code === 205)) {
          if (photoAmountRes.data.usedAmount) {
            const amountRes = Math.ceil(parseFloat(photoAmountRes.data.usedAmount) * 0.05)
            let res = await this.addSimplePhotoShareProtocols(true, false, amountRes)
            if (res.code === 200) {
              this.$toast(this.$t('lang.success'))
              this.$router.back()
            }
          }
        }
      } catch (err) {
        this.isLoading = false
      }
    },
    deletePic(e, v) {
      this.filesTemp.splice(v.index, 1)
    }
  }
}
</script>

<style lang="less" scoped>
.operation {
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  border-bottom: 1px solid #C4C5C6;
  .cancel {
    cursor: pointer;
  }
  .send {
    font-size: 14px;
    color: #FFFFFF;
    background-color: #617FFF;
    width: 60px;
    height: 30px;
    border-radius: 6px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
  }
  .loading-wrap {
    display: flex;
  }
  /deep/.van-field__control {
    font-size: 18px;
  }
}
/deep/.van-loading {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>